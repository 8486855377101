
import React from "react";

export default class ThanksLanding extends React.Component {
    render() {
        return (
            <div className="row">
                <div className="content col-12 col-sm-8">
                    <h1>Thanks!</h1>
                    <p><strong>Check your email for your private link to your sleep record.</strong> Thank you for purchasing Free Running Radar and I hope you enjoy using it. :)</p>
                    

                    <p className="missing-email">Didn't get an email? Check your spam. If you still cannot find it, email me at oliver@gonextgames.com.</p>
                </div>
            </div>
        );
    }
}

import React from "react";

export default class DonationThanksLanding extends React.Component {
    render() {
        return (
            <div className="row">
                <div className="content col-12 col-sm-8">
                    <h1>Thanks for Donating!</h1>
                    <p>I appreciate your taking the time and money to donate to Free Running Radar. If you included a message, it was emailed directly to me, and I look forward to replying.</p>
                    
                    <p className="missing-email">Didn't get an email confirmation? Check your spam. If you still cannot find it, email me at oliver@gonextgames.com.</p>
                </div>
            </div>
        );
    }
}
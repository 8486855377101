
import React from "react";
import "./Landing.css";
import { TopBar } from "./TopBar/TopBar";
import CompanyInfo from "./CompanyInfo/CompanyInfo";
import Footer from "./Footer/Footer";

export default class Landing extends React.Component {
    render() {
        return (
            <div className="row landing">
                <TopBar/>
                <div className="firstDiv"/>
                <div className="row main-content-row g-0">
                    <div className="col main-content-col">
                        {this.props.children}
                    </div>
                </div>

                <div className="firstDiv"></div>
                <CompanyInfo companyName={this.props.companyName} supportEmail={this.props.supportEmail}/>
                <Footer companyName={this.props.companyName}/>
            </div>
        );
    }
}
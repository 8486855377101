import React from "react";
import "./Footer.css"


export default class Footer extends React.Component {
    render() {
        return (
            <div className={"footer col-12 "  + this.props.colorScheme}>
                <p>{`Go Next Games LLC all rights reserved ${new Date().getFullYear().toString()}.`}</p>
            </div>
        );
    }
}
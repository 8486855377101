import React, { Component } from 'react';
import { ChevronLeft, ChevronRight, AlertCircle } from 'lucide-react';
import "./Calendar.css";
import { createWakeTimeAsync, fetchWakeTimesAsync, deleteWakeTimeAsync } from '../../management/BackendClient';
import N24CalendarView from './N24CalendarView';
import predictSleep from "./SleepPrediction"

class N24Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date(),
      predictionMethod: 'average',
      predictions: [],
      wakeTimes: [],
    };

    this.HOUR_HEIGHT = 20;
    this.DEFAULT_SLEEP_DURATION = 8;
    this.TIME_LABELS = Array.from({ length: 24 }, (_, i) => {
      const hour = i;
      return {
        hour,
        label: new Date(2023, 0, 1, hour).toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: '2-digit',
          hour12: true,
        }),
      };
    });
  }

  fetchData = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');
      
      if (!token) {
        return;
      }
      const wakeTimes = await fetchWakeTimesAsync(token);
      this.setState({ wakeTimes: wakeTimes.map(time => time.created_utc) }, () => {
        this.updatePredictions(this.state.predictionMethod);
      });
    } catch (error) {
      console.error("Failed to fetch sleep times:", error);
    }
  }
  async componentDidMount() {
    await this.fetchData()
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.predictionMethod !== this.state.predictionMethod) {
      this.updatePredictions(this.state.predictionMethod);
    }
    if (prevProps.remountKey !== this.props.remountKey) {
      await this.fetchData()
    }
  }

  updatePredictions = (method) => {
    const newPredictions = this.calculatePredictions(method);
    this.setState({ predictions: newPredictions });
  };

  calculatePredictions = (method) => {
    const { wakeTimes } = this.state;
    if (!wakeTimes || wakeTimes.length < 2) return [];

    const wakeDates = wakeTimes.map(time => new Date(time));
    return predictSleep(wakeDates, method);
  };

  navigateWeek = (direction) => {
    var newDate = new Date(this.state.currentDate);
    newDate.setDate(this.state.currentDate.getDate() + direction * 7);
    this.setState({ currentDate: newDate });
  };

  handleDeleteWakeTime = async (wakeTimeString) => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');
      
      if (!token) return;

      const success = await deleteWakeTimeAsync(token, wakeTimeString);
      if (success) {
        await this.fetchData();
      }
    } catch (error) {
      console.error("Failed to delete wake time:", error);
    }
  };

  render() {
    return <N24CalendarView
        currentDate={this.state.currentDate}
        predictionMethod={this.state.predictionMethod}
        predictions={this.state.predictions}
        wakeTimes={this.state.wakeTimes}
        showDelete={true}
        onNavigate={this.navigateWeek}
        onPredictionMethodChange={(method) => this.setState({ predictionMethod: method })}
        onDeleteWakeTime={this.handleDeleteWakeTime}
      />
    
  }
}

export default N24Calendar;

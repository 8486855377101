import React from "react";
import portrait from "./Rows/portrait_00.png"
import { getCookie } from "../management/CookieManagement";
import MockN24Calendar from "./Calendar/MockN24Calendar";

export default class MainLanding extends React.Component {
    signUp = () => {
        window.location.href = `${window.location.origin}/signup`;
    }
    goToApp = (token) => {
        window.location.href = `${window.location.origin}/app?token=${token}`;
    }

    render() {
        var token = getCookie("FREE_RUNNING_RADAR_ACCESS_TOKEN")
        var hasToken = token !== null
        return (
            <div className="contents">
                <div className="row justify-content-center download-row">
                    <div className="col pitch-col">                   
                        <h1>Make your daytime obligations without fear!</h1>
                        <ul>
                            <li>Attend appointments and events despite moving sleep times</li>
                            <li>Track and share your sleep pattern with loved ones and coworkers</li>
                            <li>Unshackle your task planning from the Sun</li>
                        </ul>
                        {hasToken ? 
                        <button className="btn main-action" onClick={()=>this.goToApp(token)} data-bs-theme="dark">Go to App →</button>
                        :
                        <button className="btn main-action" onClick={this.signUp} data-bs-theme="dark">Sign Up Now →</button>
                        }
                        <p className="button-postscript">No Subscription Fee. Name your one-time price.</p>
                    </div>
                </div>
                
                <div className="row calendar-row">
                    <div className="col">
                        <MockN24Calendar/>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col header-container">
                        <h2>Log when you wake</h2>
                        <p className="subtitle">Get sleep predictions instantly</p>
                    </div>
                </div>
                {/* <AnnoyanceRow/> */}
                {/* <div className="row justify-content-center">
                    <div className="col header-container">
                        <h2>Do daytime things with a nightime life</h2>
                        <p className="subtitle">Take control. Plan your tasks with your sleep pattern and working hours in mind.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col features">
                        <h3>Track and Share your Sleep</h3>
                        <p>Track your sleep by simply pressing a "I woke up" button every morning. Create links to your sleep cycle so your coworkers and loved ones know where you're at in your cycle. Provide documentation for medical professionals.</p>
                        
                        <h3>Estimate your Future Sleep Times</h3>
                        <p>See a projection of your sleep cycle into the future so you know whether you'll be awake for that concert, birthday, or vacation.</p>
                        
                        <h3>Plan Tasks around your Waking Days</h3>
                        <p>Manage tasks based on your waking days, not on the days of the calendar. Instead of "due by Thursday" it's "due in three of your work days". Get alerted that certain days are better for daytime activities like grocery shopping and pharmacy visits.</p>
                        <p>Tasks roll over to your next waking day, not to "Tuesday".</p>
                    </div>
                    <div className="col">
                        
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="jumbotron">
                            <div className="row">
                                <div className="col-4">
                                    <h2><span className="one-app">One App</span> for Sleep and Productivity</h2>
                                    <p>A one time payment with no subscription fees!</p>
                                    <p>Other task apps don't understand how sleep disorders affect productivity.</p>
                                    <div className="inline">
                                        {hasToken ? 
                                        <button className="btn jumbo-button-primary" onClick={()=>this.goToApp(token)} data-bs-theme="dark">Go to App →</button>
                                        :
                                        <button className="btn jumbo-button-primary" onClick={this.signUp} data-bs-theme="dark">Sign Up →</button>
                                        }
                                        <button className="btn jumbo-button-secondary" data-bs-theme="dark">Learn More</button>
                                    </div>
                                </div>
                                <div className="col-8">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col header-container">
                        <h2>The perfect fit for any circadian rhythm</h2>
                        <p className="subtitle">N24, DSPD, Insomia are all handled dynamically!</p>
                    </div>
                </div> */}
                {/* <div className="row">
                    <div className="col">
                        <div className="story">
                            <img src="https://www.blind-magazine.com/wp-content/uploads/2024/07/img-4947.jpg"/>
                            <div className="story-header">
                                <h3>Brand Name</h3>
                                <p>"I no longer want to krill myself"</p>
                                <p>Justin Pringles</p>
                                <p>N24</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="story">
                            <img src="https://pbs.twimg.com/media/GaRuXSMasAARWlj?format=jpg&name=medium"/>
                            <div className="story-header">
                                <h3>Brand Name</h3>
                                <p>"I no longer want to krill myself"</p>
                                <p>Justin Pringles</p>
                                <p>N24</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="story">
                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTzyLc8LMQIrviNz6u2EP3TtPRZZt3OlhrhHA&s"/>
                            <div className="story-header">
                                <h3>Brand Name</h3>
                                <p>"I no longer want to krill myself"</p>
                                <p>Justin Pringles</p>
                                <p>N24</p>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col personas">
                        <div className="persona">
                            <p>🧛</p>
                            <p>N24</p>
                        </div>
                        <div className="persona">
                            <p>🌙</p>
                            <p>DSPD</p>
                        </div>
                        <div className="persona">
                            <p>🫠</p>
                            <p>Insomnia</p>
                        </div>
                        <div className="persona">
                            <p>😴</p>
                            <p>Hypersomnia</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="jumbotron">
                            <div className="row flex-column flex-md-row">
                                <div className="col-12 col-md-7 jumbotron-content">
                                    <h4>Be Productive no matter when you sleep</h4>
                                    <p>Keep track of life no matter what "day" of the week.</p>
                                    <div className="testimonial">
                                        <img src={portrait} className="testimonal-portrait"/>
                                        <div className="testimonal-desc">
                                            <p>Oliver - Diagnosed with N24</p>
                                            <p>"Having a sleep disorder <strong> doesn't mean you have to put life on hold!</strong>"</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-5 solutions-col">
                                    <div className="solution-bundle">
                                        <p>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bar-chart-steps" viewBox="0 0 16 16">
                                                <path d="M.5 0a.5.5 0 0 1 .5.5v15a.5.5 0 0 1-1 0V.5A.5.5 0 0 1 .5 0M2 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5zm2 4a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5z"/>
                                            </svg> See your sleep over time</p>
                                        <p>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar2-range" viewBox="0 0 16 16">
                                                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1z"/>
                                                <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5zM9 8a1 1 0 0 1 1-1h5v2h-5a1 1 0 0 1-1-1m-8 2h4a1 1 0 1 1 0 2H1z"/>
                                            </svg> A calendar based around your waking days not the Earth's</p>
                                        <p>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/>
                                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
                                            </svg> Transparency and accountability
                                        </p>
                                    </div>
                                    {hasToken ? 
                                        <button className="btn " onClick={()=>this.goToApp(token)} data-bs-theme="dark">Go to App</button>
                                        :
                                        <button className="btn " onClick={this.signUp} data-bs-theme="dark">Sign Up</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="row justify-content-center">
                    <div className="col header-container">
                        <h3>Everything you are looking for</h3>
                        <p className="subtitle"></p>
                    </div>
                </div>
                <div className="row justify-content-center blurb-row">
                    <div className="col">
                        <div className="blurb blurb-1">
                            <h3>Appointments you'll be awake for</h3>
                            <p>See which weeks will be best for appointments.</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="blurb blurb-2">
                            <h3>Durability</h3>
                            <p>Sync your Calendar and Task tables to Free Running Radar so you always remain in control of your data.</p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="blurb blurb-3">
                            <h3>Privacy</h3>
                            <p>Set the level of access each of your coworkers and loved ones have on your sleep data, for maximum control.</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center blurb-row">
                    <div className="col-8">
                        <div className="blurb blurb-4">
                            <h3>Plan Vacations without fear</h3>
                            <p>See when your sleep cycle will be awake in the time zone of your destination! Switch the reference time zone from local to Mardrid, Tokyo, or Hong Kong to see which week is best for a vacation.</p>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="blurb blurb-5">
                            <h3>Get Diagnosed</h3>
                            <p>Collect all the data a professional needs to diagnose and support your sleep disoder medical care.</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center blurb-row">
                    <div className="col-4">
                        <div className="blurb blurb-6">
                            <h3>Nighttime Productivity</h3>
                            <p>Tasks rely on your sleep schedule, not the passage of the Sun.</p>
                        </div>
                    </div>
                    <div className="col-8">
                        <div className="blurb blurb-7">
                            <h3>Sleep Visualizations</h3>
                            <p>See how your sleep changes over time.</p>
                        </div>
                    </div>
                </div> */}
            </div>
        );
    }
}
import React from "react";

class DayProgressBar extends React.Component {
  constructor(props) {
    super(props);
    this.DEFAULT_AWAKE_DURATION = 16 * 60 * 60 * 1000; // 16 hours in milliseconds
    this.state = {
      dayProgress: 0,
      latestWakeTime: null,
      endTime: null,
      nineAMPosition: 0,
      fivePMPosition: 0,
    };
  }

  componentDidMount() {
    this.calculateProgress();
    this.timer = setInterval(this.calculateProgress, 60000); // Update progress every minute
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.wakeTimes.length !== prevProps.wakeTimes.length)
    this.calculateProgress();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  calculateProgress = () => {
    const { wakeTimes } = this.props;

    if (!wakeTimes || wakeTimes.length === 0) return;

    // Sort sleep times and find the latest wake time
    const sortedTimes = wakeTimes.map((time) => new Date(time)).sort((a, b) => b - a);
    const latestWakeTime = sortedTimes[0];
    const endTime = new Date(latestWakeTime.getTime() + this.DEFAULT_AWAKE_DURATION);

    // Calculate day progress
    const currentTime = new Date();
    const timeElapsed = currentTime - latestWakeTime;
    const dayProgress = Math.min((timeElapsed / this.DEFAULT_AWAKE_DURATION) * 100, 100);

    // Calculate positions for 9 AM and 5 PM markers
    const calculatePosition = (targetTime) => {
      const elapsed = targetTime - latestWakeTime;
      return Math.min((elapsed / this.DEFAULT_AWAKE_DURATION) * 100, 100);
    };

    const nineAM = new Date(latestWakeTime).setHours(9, 0, 0, 0);
    const fivePM = new Date(latestWakeTime).setHours(17, 0, 0, 0);
    const nineAMPosition = calculatePosition(nineAM);
    const fivePMPosition = calculatePosition(fivePM);

    // Update state
    this.setState({
      dayProgress,
      latestWakeTime,
      endTime,
      nineAMPosition,
      fivePMPosition,
    });
  };

  // Date formatting function
  formatDate = (date) => {
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    }) + " " + date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).toLowerCase();
  };

  render() {
    const { dayProgress, latestWakeTime, endTime, nineAMPosition, fivePMPosition } = this.state;
    const markersReady = latestWakeTime !== null && nineAMPosition >= 0 && nineAMPosition <= 100 && fivePMPosition >= 0 && fivePMPosition <= 100;
    
    return (
      <div>
        {/* Date and Time Labels with reserved space */}
        <div className="progress-labels">
          <div className="label-start">
            {latestWakeTime ? `Wake ${this.formatDate(latestWakeTime)}` : <span>&nbsp;</span>}
          </div>
          <div className="label-end">
            {endTime ? `${this.formatDate(endTime)} Sleep` : <span>&nbsp;</span>}
          </div>
        </div>
  
        <div className="progress-container">
          <div className="progress">
            {/* 9 AM and 5 PM Lines */}
            {latestWakeTime && endTime && markersReady ? (
              <>
                <div className="marker" style={{ left: `${nineAMPosition}%` }} />
                <div className="marker" style={{ left: `${fivePMPosition}%` }} />
              </>
            ) : null}
  
            {/* Progress Bar */}
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${dayProgress}%` }}
              aria-valuenow={dayProgress}
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
  
          {/* 9 AM and 5 PM Labels Below the Progress Bar with reserved space */}
          <div className="marker-container">
            <div
              className="marker-label"
              style={{
                left: markersReady ? `${nineAMPosition}%` : '0%',
                visibility: markersReady ? 'visible' : 'hidden',
              }}
            >
              9 AM
            </div>
            <div
              className="marker-label"
              style={{
                left: markersReady ? `${fivePMPosition}%` : '100%',
                visibility: markersReady ? 'visible' : 'hidden',
              }}
            >
              5 PM
            </div>
          </div>
        </div>
      </div>
    );
  }
  
}

export default DayProgressBar;

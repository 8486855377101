import React from "react";
import "../TopBar.css"

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-router-dom";
import NavbarLink from "./NavbarLink";
import Nav from 'react-bootstrap/Nav';
import { clearCookie,getCookie } from "../../../management/CookieManagement";
import { checkIfTokenIsValid } from "../../../management/BackendClient";

export class TopBar extends React.Component {    
    render() {
        var isAwayFromHome = window.location.pathname !== "" && window.location.pathname !== "/"
        return (
            <Navbar fixed="top" className="top-bar-navbar">
                <Container>
                    <Navbar.Brand>
                        <Link to="/">Free Running Radar</Link>
                    </Navbar.Brand>
                    <Nav className="justify-content-end">                        
                        {/* <NavbarLink endpointName="Home" isGlobalEndpoint={false} endpoint={""}/> */}
                    </Nav>                                       
                </Container>
            </Navbar>
        );
    }
}
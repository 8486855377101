
import React from "react";

export default class FailedPurchaseLanding extends React.Component {
    render() {
        return (
            <div className="row">
                <div className="content col-12 col-sm-8">
                    <h1>Oops!</h1>
                    <p>We were unable to complete your purchase! We apologize for the inconvenience.</p>
                </div>
            </div>
        );
    }
}
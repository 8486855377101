import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import BootstrapSizeIndicator from "./SizeIndicator";
import MainLanding from "./components/MainLanding";
import Landing from "./components/Landing/Landing";
import PrivacyLanding from "./components/Landing/CompanyInfo/PrivacyLanding";
import TermsOfUseLanding from "./components/Landing/CompanyInfo/TermsOfUseLanding";
import ThanksLanding from "./components/Landing/CompanyInfo/ThanksLanding";
import FailedPurchaseLanding from "./components/Landing/CompanyInfo/FailedPurchaseLanding";
import SignupLanding from "./components/Landing/CompanyInfo/SignupLanding";
import AppLanding from "./components/AppLanding";
import DonationThanksLanding from "./components/Landing/CompanyInfo/DonationThanksLanding";

export default class App extends React.Component {
    state= {
    }    
    render = () => {
        const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '::1';
        
        return <BrowserRouter>
            <div className="App container-fluid">
                {isLocalhost && <BootstrapSizeIndicator/>}
                
                <Landing>
                    <Routes>
                        <Route key="home" path="/" element={
                            <MainLanding/>
                        }/>
                        <Route key="thanks" path="/thanks" element={
                            <ThanksLanding/>
                        }/>
                        <Route key="donation-thanks" path="/donation-thanks" element={
                            <DonationThanksLanding/>
                        }/>
                        {/* <Route key="failed-purchase" path="/failed-purchase" element={
                            <FailedPurchaseLanding/>
                        }/> */}
                        <Route key="app" path="/app" element={
                            <AppLanding/>
                        }/>
                        <Route key="signup" path="/signup" element={
                            <SignupLanding/>
                        }/>
                        <Route key="home" path="/privacy" element={
                            <PrivacyLanding/>
                        }/>
                        <Route key="home" path="/terms" element={
                            <TermsOfUseLanding/>
                        }/>
                    
                        {/* <Route key="thanks" path="/thanks" element={
                            <ThanksLanding/>
                        }/> */}
                        
                    </Routes>
                </Landing>
            </div>
        </BrowserRouter>    
    }
}

import React from "react";
import "./CompanyInfo.css"
import { Link } from "react-router-dom";

export default class LinkList extends React.Component {
    render() {
        var links = this.props.links.map((link) =>
            <li key={link.name} className="link-list-li">
                {link.type === "localLink" ? 
                    <Link to={link.url}>{link.name}</Link>
                    :
                    <a href={link.url}>{link.name}</a>
                }
            </li>
        )

        return (
            <div className="col-12 col-sm-4">
                <h4 className="link-list-name-header">{this.props.listName}</h4>
                <ul className="link-list-ul">
                    {links}
                </ul>
            </div>
        );
    }
}
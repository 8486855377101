import React from "react";
import {attemptPurchase} from "../../../management/BackendClient"

export default class SignupLanding extends React.Component {
    state = {
        email: undefined,
        price: 9.99
    }
    isEmailValidInvalid = (email) => {
        return !String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };
    updateEmail = (email) => {
        this.setState({email: email})
    }

    updatePrice = (price) => {
        // const validPrice = Math.max(0.01, parseFloat(price) || 0.01)
        this.setState({price: price})
    }

    render() {
        var isEmailInvalid = this.isEmailValidInvalid(this.state.email)
        var isPriceInvalid = isNaN(this.state.price) || parseFloat(this.state.price) < 0.50
        return (
            <div className="row">
                <div className="content col-12 col-sm-8">
                    <div className="signup-pane">                        
                        <h3>Signup for Free Running Radar</h3>
                        <p>Upon purchasing a license you will get a private link to the Free Running Radar app.</p>
                        <p>But to get started managing your sleep, I need your email.</p>
                        <div className="input-group mb-3">
                            <input 
                                type="email" 
                                className="form-control"
                                placeholder="Enter your email" 
                                aria-label="Email" 
                                value={this.state.email || ''} 
                                onChange={(e) => this.updateEmail(e.target.value)}
                            />
                        </div>
                        <p>Free Running Radar is a one time license, but it's name your price.</p>
                        <div className="input-group mb-3">
                            <span className="input-group-text">$</span>
                            <input 
                                type="text" 
                                className="form-control"
                                placeholder="Enter amount (minimum $0.01)" 
                                aria-label="Amount" 
                                // min="0.01"
                                // step="0.01"
                                value={this.state.price} 
                                onChange={(e) => this.updatePrice(e.target.value)}
                            />
                        </div>
                        <button 
                            className="btn signup-button" 
                            disabled={isEmailInvalid || isPriceInvalid} 
                            onClick={async () => attemptPurchase(
                                this.state.email, 
                                Math.round(this.state.price * 100)
                            )}
                        >
                            {isEmailInvalid 
                                ? "Enter your Email" 
                                : isPriceInvalid 
                                    ? "Enter a valid price (min $0.50)"
                                    : "Continue to Stripe Checkout"
                            }
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}
// MockN24Calendar.js

import React, { Component } from 'react';
import N24CalendarView from './N24CalendarView';
import predictSleep from "./SleepPrediction"

class MockN24Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date(),
      predictionMethod: 'average',
      predictions: [],
      wakeTimes: [],
    };
  }

  componentDidMount() {
    this.generateMockData();
  }

  generateMockData = () => {
    const sleepDurationHours = 8;
    const shiftPerDay = -2; // Advance by roughly 1 hour per day
    const mockWakeTimes = [];
    let startSleepTime = new Date();
    startSleepTime.setHours(0, 0, 0, 0); // Start from midnight of today

    // Generate 14 days of wake times, moving backwards in time
    for (let i = 0; i < 14; i++) {
      const noise = Math.floor(Math.random() * 3) + 1; // Random noise between 1 and 3 hours
      const adjustedSleepTime = new Date(startSleepTime);
      adjustedSleepTime.setHours(startSleepTime.getHours() + noise);

      const wakeTime = new Date(adjustedSleepTime.getTime() + sleepDurationHours * 60 * 60 * 1000);
      mockWakeTimes.unshift(wakeTime); // Add wake time to the beginning to reverse the order

      // Move sleep start time back by 1 day with the shift and add random noise
      startSleepTime.setDate(startSleepTime.getDate() - 1);
      startSleepTime.setHours(startSleepTime.getHours() + shiftPerDay);
    }

    this.setState({ wakeTimes: mockWakeTimes }, () => {
      this.updatePredictions(this.state.predictionMethod);
    });
  };

  updatePredictions = (method) => {
    const newPredictions = this.calculatePredictions(method);
    console.log(newPredictions)
    this.setState({ predictions: newPredictions });
  };

  calculatePredictions = (method) => {
    const { wakeTimes } = this.state;
    if (!wakeTimes || wakeTimes.length < 2) return [];

    return predictSleep(wakeTimes, method)
  };

  navigateWeek = (direction) => {
    this.setState((prevState) => ({
      currentDate: new Date(prevState.currentDate.setDate(prevState.currentDate.getDate() + direction * 7))
    }));
  };

  render() {
    const { currentDate, predictionMethod, predictions, wakeTimes } = this.state;
    return (
      <N24CalendarView
        currentDate={currentDate}
        predictionMethod={predictionMethod}
        predictions={predictions}
        showDelete={false}
        wakeTimes={wakeTimes}
        onNavigate={this.navigateWeek}
        onPredictionMethodChange={(method) => this.setState({ predictionMethod: method })}
      />
    );
  }
}

export default MockN24Calendar;

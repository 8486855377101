const DEFAULT_SLEEP_DURATION = 8
const predictSleep = (wakeTimes, method) => {
    

    const sortedData = [...wakeTimes].sort((a, b) => a - b);
    const intervals = [];

    for (let i = 1; i < sortedData.length; i++) {
      const diff = sortedData[i] - sortedData[i - 1];
      intervals.push(diff / (1000 * 60 * 60));
    }

    let predictedInterval;
    switch (method) {
      case 'average':
        predictedInterval = intervals.reduce((a, b) => a + b) / intervals.length;
        break;
      case 'recent':
        const recentIntervals = intervals.slice(-5);
        predictedInterval = recentIntervals.reduce((a, b) => a + b) / recentIntervals.length;
        break;
      case 'median':
        const sorted = [...intervals].sort();
        predictedInterval = sorted[Math.floor(sorted.length / 2)];
        break;
      default:
        predictedInterval = 24;
    }

    const predictions = [];
    let lastTime = new Date(sortedData[sortedData.length - 1]);

    for (let i = 0; i < 30; i++) {
      const nextWake = new Date(lastTime.getTime() + predictedInterval * 60 * 60 * 1000);
      predictions.push({
        start: new Date(nextWake.getTime() - DEFAULT_SLEEP_DURATION * 60 * 60 * 1000),
        end: nextWake,
        predicted: true,
      });
      lastTime = nextWake;
    }

    return predictions;
  };

export default predictSleep
import React from "react";
import { fetchWakeTimesAsync, createWakeTimeAsync, attemptDonation } from "../management/BackendClient";
import DayProgressBar from "./Calendar/DayProgressBar";
import "./AppLanding.css";
import N24Calendar from "./Calendar/N24Calendar";
import { getCookie, setCookie } from "../management/CookieManagement";

export default class AppLanding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      wakeTimes: [],
      remountKey: 0,
      previousWakeTime: new Date(),
      donationAmount: 100,
      donationMessage: ""
    };
  }

  async componentDidMount() {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      let token = urlParams.get("token") || getCookie("FREE_RUNNING_RADAR_ACCESS_TOKEN");

      if (token === null) {
        return;
      }
      setCookie("FREE_RUNNING_RADAR_ACCESS_TOKEN", token, 7);
      let wakeTimes = await fetchWakeTimesAsync(token);
      wakeTimes = wakeTimes.map((time) => new Date(time.created_utc));

      // Sort wakeTimes in descending order and get the most recent wake-up time
      wakeTimes.sort((a, b) => b - a);

      this.setState((prevState) => ({
        wakeTimes: wakeTimes,
        remountKey: prevState.remountKey + 1
      }));

    } catch (error) {
      console.error("Failed to fetch wake times:", error);
    }
  }

  handlePreviousWakeTimeChange = (event) => {
    this.setState({ previousWakeTime: new Date(event.target.value) });
  };

  wakeUp = async (usePreviousTime = false) => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    if (!token) {
      return;
    }

    let newWakeTime;
    if (usePreviousTime) {
      const timestamp = this.state.previousWakeTime.toISOString().slice(0, -1);
      await createWakeTimeAsync(token, timestamp);
      newWakeTime = this.state.previousWakeTime;
    } else {
      await createWakeTimeAsync(token);
      newWakeTime = new Date();
    }

    this.setState((prevState) => ({
      wakeTimes: [newWakeTime, ...prevState.wakeTimes],
      remountKey: prevState.remountKey + 1
    }));
  };

  // Helper function to check if the latest wake time is within 4 hours
  isTooSoonToWakeAgain = () => {
    const { wakeTimes } = this.state;
    if (wakeTimes.length === 0) return false;

    const mostRecentWakeTime = new Date(wakeTimes[0]).getTime();
    const currentTime = new Date().getTime();
    const cooldownMilliseconds = 12 * 60 * 60 * 1000;    
    return currentTime - mostRecentWakeTime < cooldownMilliseconds;
};


  render() {
    const { wakeTimes } = this.state;
    const reportingDisabled = this.isTooSoonToWakeAgain();

    const localDateTimeString = new Date(this.state.previousWakeTime.getTime() - this.state.previousWakeTime.getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, 16);

    return (
      <div className="contents">
        <div className="row">
          <div className="col">
            <div className="progress-and-reporting">
              <span className="info-tooltip">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                  <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
                </svg>
                <span className="tooltip-text">
                  This section tracks your progress throughout the day based on your wake times. You can record a new wake-up time using the button below.
                </span>
              </span>
              <h4>Day Progress</h4>
              <DayProgressBar wakeTimes={wakeTimes} />
              <div className="wake-time-inputs">
                <div className="previous-wake-time">
                  <input
                    type="datetime-local"
                    onChange={this.handlePreviousWakeTimeChange}
                    value={localDateTimeString}
                  />
                  <button
                    className="btn previous-wake-up-button"
                    onClick={() => this.wakeUp(true)}
                  >
                    Record Previous Wake-Up
                  </button>
                </div>
                <button
                  disabled={reportingDisabled}
                  className="btn wake-up-button"
                  onClick={() => this.wakeUp(false)}
                >
                  Record Wake-Up Now
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row n24-calendar-row">
          <div className="col">
            <N24Calendar remountKey={`n24-calendar-${this.state.remountKey}`}/>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h4 className="donate-header">Donate</h4>
            <div className="donation-container">
              <p className="donation-explanation">If you find this app helpful, please consider donating to support its development and maintenance. This disorder can be a real challenge, and your support can make a big difference towards helping others.</p>
              
              <div className="input-group mb-3">
                <span className="input-group-text">$</span>
                <input 
                  type="number" 
                  min="0.5"
                  placeholder="Amount in USD"
                  value={this.state.donationAmount / 100}
                  onChange={(e) => this.setState({ donationAmount: Math.floor(e.target.value * 100) })}
                  className="donation-input"
                />
              </div>
              
              <textarea
                placeholder="Optional message"
                onChange={(e) => this.setState({ donationMessage: e.target.value })}
                value={this.state.donationMessage}
                className="donation-message"
                rows="3"
              />
              <button 
                className="btn donate-button"
                onClick={() => attemptDonation(this.state.donationAmount, this.state.donationMessage)}
                disabled={!this.state.donationAmount || this.state.donationAmount < 50}
              >
                Donate through Stripe
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
